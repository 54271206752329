import { createGlobalStyle } from 'styled-components';

const EmbedGlobalStyle = createGlobalStyle`
  html, body, #root, #__next {
    background: none;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export default EmbedGlobalStyle;
