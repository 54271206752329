export enum SegmentEvents {
  videoRequestCompleted = 'Video Request Completed',
  videoRequestCancel = 'Video Request Cancelled',
  videoRequestFormSubmit = 'Video Request Form Submitted',
  viralLinkClick = 'Viral Link Clicked',
  videoRequestCapture = 'Video Request Capture',
  campaignVideoPlayed = 'Campaign Video Played',
  campaignResponseClicked = 'Campaign Response Clicked',
  campaignCTAClicked = 'Campaign CTA Clicked',
  dynamicCampaignViewed = 'Dynamic Campaign Viewed',
  ErrorMessageThrown = 'Error Message Thrown',
  PASSWORD_PROTECTED_ENTERED = "Password Protected Entered",
}
