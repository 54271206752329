/* eslint-disable no-undef */
import { useEffect, useState } from 'react';

import { isVideoCreator } from '@utils/index';
import { Campaign, EnvConfigShare } from '@interfaces';

import { useLocalStorageV2 } from './lib/useLocalStorageV2';


const useIsOwnerVideo = ({ campaign, env }: { campaign: Campaign, env: EnvConfigShare }) => {
  const [isOwnerVideo, setisOwnerVideo] = useState<boolean>(false);
  const { getLocalStorageV2 } = useLocalStorageV2(env.cryptoKey)

  useEffect(() => {
    setisOwnerVideo(isVideoCreator(campaign?.creator?._id, getLocalStorageV2))
  }, []);

  const checkIsOwnerVideo = () => setisOwnerVideo(isVideoCreator(campaign?.creator?._id, getLocalStorageV2));


  return { isOwnerVideo, checkIsOwnerVideo };
};

export default useIsOwnerVideo;
