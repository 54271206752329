import * as React from 'react';
import Head from 'next/head';

import RenderIf from '@components/RenderIf';
import { Campaign } from '@interfaces';
import OpenGraph from '@components/Layout/components/OpenGraph';
import EmbedGlobalStyle from '@components/Layout/styles/EmbedGlobalStyle';

interface IHeadersProps {
  campaign: Campaign;
  title?: string;
  sendsparkBrandingEnabled?: boolean;
  embed?: boolean;
  isLinkedInRequest?: boolean;
}

const Headers: React.FunctionComponent<IHeadersProps> = ({
  campaign,
  title = '',
  sendsparkBrandingEnabled,
  embed = false,
  isLinkedInRequest = false,
}) => {
  const defaultFavicon = sendsparkBrandingEnabled ? '/assets/favicon/favicon.png' : '/assets/alternative-favicon/sendspark-white-label-favicon.png'
  const favicon = !campaign?.whiteLabelAllowed
    ? '/assets/favicon/favicon.png'
    : campaign?.meta?.favicon?.url || defaultFavicon;

  return (
    <>
      <OpenGraph
        campaign={campaign}
        sendsparkBrandingEnabled={sendsparkBrandingEnabled}
        isLinkedInRequest={isLinkedInRequest}
      />
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Regular.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Regular.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Bold.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Inter/Inter-Bold.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit-Medium.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit-Medium.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit Bold.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit Bold.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit-Regular.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit-Regular.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit-Light.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/Larsseit/Type Dynamic - Larsseit-Light.ttf"
          as="font"
          crossOrigin=""
        />
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <meta name="googlebot" content="noindex" />
      </Head>
      <RenderIf condition={embed}>
        <EmbedGlobalStyle />
      </RenderIf>
    </>
  );
};

export default Headers;
