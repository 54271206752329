/* eslint-disable no-use-before-define */
import { DynamicVariables as DynamicCustomVariables } from '@sendspark/js-api-sdk';

export interface Template {
  fields: {
    callToAction: { label: string; url: string }[];
    header: string;
    message: string;
  };
  _id: string;
  name: string;
  workspace: string;
  creator: string;
  lastUsedAt: string | null;
  userLastUsed: string | null;
  userLastUpdated: string | null;
  createdAt: string;
  logo: {
    url: string;
  };
  sendsparkBrandingEnabled: boolean;
  styles: CampaignStyle;
  calendar?: CampaignCalendar;
  theme?: {
    name: string;
    properties: Record<string, any>;
  };
  featureFlips?: Record<string, boolean>;
}

export interface EnvConfig {
  apiV2Url: string;
  apiV1Url: string;
  pusherKey?: string;
  segmentApiKey?: string;
  pusherCluster?: string;
  nodeEnv: string;
  sendsparkSiteUrl?: string;
  nextPublicSendsparkSite?: string;
  dashboardUrl: string;
  appUrl: string;
  editPageUrl?: string;
}
export interface EnvConfigShare extends EnvConfig {
  muxDataEnv: string;
  muxDataDebug: boolean;
  beaconUrl: string;
  beaconDebug: boolean;
  ignoreTracking?: boolean;
  excludedIpForBeaconTracking?: Array<string>;
  excludedIpForAnalytics?: Array<string>;
  videoProcessingGIF?: string;
  autoPlay?: boolean;
  cryptoKey?: string;
}
export interface EditPageUpdate {
  title?: string;
  message?: string;
  cta?: CampaignMessage | CampaignMessage[];
  ctaActive?: boolean;
  sources?: CampaignMetaVideoSource[];
  meta?: CampaignMetaVideo;
  style?: CampaignStyle;
  calendar?: CampaignCalendar;
}

export interface PreviewTemplatePageUpdate {
  calendar?: CampaignCalendar;
  fields: {
    calendar: any;
    callToAction: { label: string; url: string }[];
    header: string;
    message: string;
  };
}

export interface CampaignCreator {
  avatar: null;
  name?: string;
  firstName: string;
  lastName: string;
  workspaceId: string;
  workspaceLogo: string;
  workspaceName: string;
  _id: string;
  email: string;
}

export interface CampaignFolder {
  _id: string;
  id: string;
  kind: string;
  name: string;
  org: string;
  users: Array<string>;
}

export interface CampaignMetaVideoSource {
  src: string;
  type: string;
}

export interface CampaignMetaVideo {
  aspectRatio: string;
  created: string;
  duration: number;
  ext: string;
  id: string;
  maxResolution: string;
  mime: string;
  muxAssetId: string;
  muxMP4Ready: boolean;
  muxStatus: 'ready' | 'preparing';
  muxPlaybackId: string;
  name: string;
  sources: Array<CampaignMetaVideoSource>;
  stored: boolean;
  thumbnail: string;
  thumbnailStatic: string;
  url: string;
  uploadingStatus?: 'pending' | 'uploading' | 'errored' | 'completed';
}
export interface Avatar {
  aspectRatio: string;
  created: string;
  duration: number;
  ext: string;
  id: string;
  maxResolution: string;
  mime: string;
  muxAssetId: string;
  muxMP4Ready: boolean;
  muxPlaybackId: string;
  name: string;
  sources: Array<CampaignMetaVideoSource>;
  stored: boolean;
  thumbnail: string;
  thumbnailStatic: string;
  url: string;
}
export interface WorkspaceLogo {
  aspectRatio: string;
  created: string;
  duration: number;
  ext: string;
  id: string;
  maxResolution: string;
  mime: string;
  muxAssetId: string;
  muxMP4Ready: boolean;
  muxPlaybackId: string;
  name: string;
  sources: Array<CampaignMetaVideoSource>;
  stored: boolean;
  thumbnail: string;
  thumbnailStatic: string;
  url: string;
}

export interface CampaignMetaLogoSource {
  src: string;
  type: string;
}

export interface CampaignMetaLogo {
  created: string;
  ext: string;
  id: string;
  mime: string;
  muxMP4Ready: boolean;
  name: string;
  sources: Array<CampaignMetaLogoSource>;
  stored: boolean;
  thumbnail?: string;
  thumbnailStatic: string;
  url: string;
}

export interface CampaignMetaFavicon {
  created: string;
  ext: string;
  id: string;
  mime: string;
  muxMP4Ready: boolean;
  name: string;
  sources: Array<CampaignMetaLogoSource>;
  stored: boolean;
  thumbnail?: string;
  thumbnailStatic: string;
  url: string;
}

export interface CampaignMeta {
  documentTitle: string;
  heading1: string;
  heading2: string;
  name: string;
  speakerName: string;
  speakerImage?: string;
  logo?: CampaignMetaLogo;
  favicon?: CampaignMetaFavicon; // TODO: Remove this property on the refactor, must be part of styles
  video?: CampaignMetaVideo; // TODO: Remove this property on the refactor, must be part of styles
  videoProcessing?: boolean;
}

export interface CampaignCalendar {
  provider?: string;
  link?: string;
  codeSnippets?: Array<{
    position?: string;
    snippet?: string;
  }>
}
export interface CampaignSources {
  overlay: string;
  overlayOmitPlayButton: string;
  thumbnailAnimated: string;
  thumbnailAnimatedOmitPlayButton: string;
  thumbnailStatic: string;
  thumbnailStaticOmitPlayButton: string;
  thumbnailDynamic: string;
}

export interface CampaignStyle {
  CTAStyle: string;
  CTAType: string;
  avatar?: Avatar;
  background: string;
  favicon?: CampaignMetaFavicon;
  fontFamily?: string;
  heading1: string;
  heading2: string;
  isUserGlobalStylesModified: boolean;
  logo?: CampaignMetaLogo;
  logoSize?: number;
  messageLeftBackground?: string;
  messageLeftText?: string;
  messageRightBackground?: string;
  messageRightText?: string;
  outlinePlayButtonColor: string;
  playButtonStyle: string;
  playButtonTransparency: string;
  responseButtonBackground: string;
  responseButtonText: string;
  solidPlayButtonColor: string;
  videoButtonBackground?: string;
  videoButtonIcon?: string;
  workspaceLogo: WorkspaceLogo;
  backgroundColor?: string;
  titleColor?: string;
  messageColor?: string;
  borderColor?: string;
  iconColor?: string;
}

export enum thumbnailImageFormat {
  ANIMATED = 'animated',
  STATIC = 'static',
}

export interface CampaignThumbnail {
  clearbitLogo: {
    name: string;
    domain: string;
    logo: string;
  };
  clearbitLogoColor?: string;
  showTime: boolean;
  start: number;
  color?: string;
  logo: CampaignMetaLogo;
  caption?: string;
  isGenerated?: 'generating' | 'done';
  videoAsset: {
    thumbnail: string;
    thumbnailStatic: string;
    aspectRatio: string;
  };
  imageFormat: thumbnailImageFormat;
}

export type CampaignMessageKind = 'text' | 'link' | 'button' | 'asset';

export type CampaignMessageView = 'rounded' | 'square';

export type CampaignMessageSide = 'left' | 'right';

export interface CampaignMessage {
  kind: CampaignMessageKind;
  link: string;
  text: string;
  view: CampaignMessageView;
  asset?: { url: string }; // not finished
  background?: string;
  side?: CampaignMessageSide;
}

export interface CampaignStats {
  views: number;
  plays: number;
  responses: number;
  ctas: number;
  thumbsUp: number;
}

export interface CampaignTemplate {
  title: string;
  tags: string;
  description: string;
}

export interface NavbarThemeStyle {
  background?: string;
  iconColor?: string;
  padding?: string;
}

export enum PlayerAnimation {
  ENVELOPE = 'envelope',
}

export interface Theme {
  name: string;
  properties?: {
    bannerImageUrl?: string;
    FAQSection?: boolean;
    videoMessageBy?: boolean;
    player?: {
      animation?: PlayerAnimation;
    },
    style?: {
      navbar?: NavbarThemeStyle;
      heading1?: string;
      heading2?: string;
      background?: string;
      backgroundImage?: string;
      backgroundGradient?: string;
      responseButtonBackground?: string;
      responseButtonText?: string;
      mainContentBackground?: string;
      hideMainContentBorders?: boolean;
      mobileLayout?: {
        marginTop?: string;
        width?: string;
      };
      bannerSection?: {
        container?: {
          background?: string;
          height?: string;
        };
        logo?: {
          url?: string;
        };
      };
      titleSection?: {
        container: {
          padding?: string;
        };
        title: {
          fontFamily?: string;
          fontSize?: string;
          fontWeight?: string;
        };
      };
      videoSection: {
        container?: {
          padding?: string;
          background?: string;
        };
      };
      descriptionSection: {
        mobileContainer?: {
          padding?: string;
        };
        container?: {
          padding?: string;
          background?: string;
        };
        text?: {
          fontSize?: string;
          fontFamily?: string;
          fontWeight?: string;
        };
      };
      actionSection?: {
        buttonBorderRadius?: string;
        mobileContainer?: {
          padding?: string;
        };
        container?: {
          padding?: string;
          background?: string;
        };
        ctaButton?: {
          fontFamily?: string;
          fontWeight?: string;
        };
      };
    };
  };
}

interface CustomVariable {
  name: keyof DynamicVariables;
  value: any;
}

export interface DynamicVideoProps {
  version?: number;
  dynamic?: string;
  backgroundUrl?: string;
  backgroundType?: string;
  contact?: Record<string, any>;
  customVariables?: Array<CustomVariable>;
}

export interface Campaign {
  creator: CampaignCreator;
  featureFlips: Record<string, boolean>;
  hideMessageBy: boolean;
  folder: CampaignFolder;
  _id: string;
  id: string;
  messages: Array<CampaignMessage>;
  length?: number;
  meta: CampaignMeta;
  requestActive?: boolean;
  template?: CampaignTemplate;
  shareId?: string;
  sources?: CampaignSources;
  style: CampaignStyle;
  styles?: CampaignStyle;
  stats?: CampaignStats;
  thumbnail?: CampaignThumbnail;
  workspaceName: string;
  workspaceLogo: WorkspaceLogo;
  reactionIds?: Array<string>;
  sendsparkBrandingEnabled?: boolean;
  whiteLabelAllowed?: boolean;
  referralCode?: string;
  theme?: Theme;
  domain?: string;
  shareUrl?: string;
  requestUrl?: string;
  requestId: string;
  userIntegrations: Array<any>;
  createdAt?: string;
  dynamicVariablesEnable?: boolean;
  version?: string;
  autoPlay?: boolean;
  thumbsUp?: boolean;
  gapCombinedVideoPlayer: boolean;
  dynamicVideoProps?: DynamicVideoProps;
  isFreeWorkspace?: boolean
  calendar?: CampaignCalendar;
  restricted?: boolean;
  restrictionReason?: string;
}

export interface DynamicVariables extends Partial<DynamicCustomVariables> {
  lastname?: string;
}
