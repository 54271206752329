import { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Campaign, thumbnailImageFormat } from '@interfaces';
import { reemplaceVariables } from '@utils';
import { aspectRatioToPaddingBottomPercentage } from './util';
import { MUX_STATUS } from '@constants/constants';

interface Props {
  campaign: Campaign;
  sendsparkBrandingEnabled?: boolean;
  isLinkedInRequest?: boolean;
}

const OpenGraph: FC<Props> = ({
  campaign,
  sendsparkBrandingEnabled = true,
  isLinkedInRequest = false,
}) => {
  const router = useRouter();
  
  const baseUrl = campaign.domain || '';
  const shareUrl = campaign.shareUrl || '';
  const apiv2Url = process.env.NEXT_PUBLIC_API_V2_URL;
  const facebookAppId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;

  if (!campaign.restricted) {
    if (!baseUrl || !apiv2Url || !facebookAppId || !shareUrl) return null;
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const queryParams = router.asPath.split('?');
  const url = `${shareUrl}${router?.query?.id || ''}${
    queryParams[1] ? `?${queryParams[1]}` : ''
  }`;
  const oembedUrl = `${shareUrl}${router?.query?.id || ''}${
    queryParams[1] ? `&${queryParams[1]}` : ''
  }`;

  let title =
    campaign.meta.heading1 || campaign.meta.heading2 || 'Video Message';

  if (campaign?.featureFlips?.dynamicVariables) {
    title = reemplaceVariables(
      title,
      router.query,
      campaign.dynamicVariablesEnable
    );
  }

  let description = 'Watch this video I made for you';
  let siteName = campaign?.workspaceName;

  const embedUrl = url.replace('/share/', '/embed/');
  const percentage = aspectRatioToPaddingBottomPercentage(
    campaign?.thumbnail?.videoAsset?.aspectRatio
  );
  
  let animatedThumbnailAnimated = campaign?.thumbnail?.videoAsset?.thumbnail
  let staticThumbnailOmitted = campaign?.thumbnail?.videoAsset?.thumbnailStatic
  const dynamicThumbnailOmmited = campaign?.sources?.thumbnailDynamic

  if (campaign?.featureFlips?.shareFaster) {
    animatedThumbnailAnimated = campaign?.sources?.thumbnailAnimated
    staticThumbnailOmitted = campaign?.sources?.thumbnailStatic
  }

  let thumbnailToUse = staticThumbnailOmitted

  if (campaign?.thumbnail?.imageFormat === thumbnailImageFormat.ANIMATED && isLinkedInRequest) {
    // Use animated thumbnail for LinkedIn only
    if (
      campaign?.featureFlips?.dynamicVariables && 
      dynamicThumbnailOmmited && 
      Object.keys(router?.query || {}).length > 1
    ) {
      let thumbnailURL = `${dynamicThumbnailOmmited}?`
  
      if (router?.query?.firstname) {
        thumbnailURL = `${thumbnailURL}firstname=${router.query.firstname}`
      }
  
      if (router?.query?.jobtitle) {
        thumbnailURL = `${thumbnailURL}&jobtitle=${router.query.jobtitle}`
      }
  
      if (router?.query?.companyname) {
        thumbnailURL = `${thumbnailURL}&companyname=${router.query.companyname}`
      }
  
      thumbnailToUse = encodeURI(thumbnailURL)
    } else {
      thumbnailToUse = animatedThumbnailAnimated
    }
  }
  
  if (!campaign?.whiteLabelAllowed && !campaign.restricted) {
    siteName = 'Sendspark';
    title = 'Video Message by Sendspark';
    description = 'Create and share videos for free at sendspark.com';
  }

  const twitterCreator = sendsparkBrandingEnabled ? '@sendspark' : '';

  const isVideoReady = campaign?.meta?.video?.muxStatus === MUX_STATUS.READY
  
  return (
    <>
      <Head>
        <link
          rel="alternate"
          type="application/json+oembed"
          href={`${apiv2Url}/api/v1/campaigns/oembed?url=${oembedUrl}`}
        />
        <meta property="fb:app_id" content={facebookAppId} />

        {/* Linkedin */}
        <meta property="og:image" content={thumbnailToUse} />
        <meta property="og:image:secure_url" content={thumbnailToUse} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:alt" content="Thumbnail for the video" />
        <meta property="og:image:width" content="500" />
        <meta
          property="og:image:height"
          content={`${Math.floor(percentage * 500.0)}`}
        />

        <meta property="og:video" content={embedUrl} />
        <meta property="og:video:secure_url" content={embedUrl} />
        <meta property="og:video:type" content="text/html" />
        <meta property="og:video:width" content="960" />
        <meta
          property="og:video:height"
          content={`${Math.floor(960 * percentage)}`}
        />

        {/* Defaults */}
        <meta property="og:site_name" content={siteName} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="video.other" />

        {/* Twitter */}
        {isVideoReady && (
          <>
            <meta name="twitter:card" content="player" />
            <meta name="twitter:creator" content={twitterCreator} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:domain" content={baseUrl} />
            <meta name="twitter:image" content={thumbnailToUse} />
            <meta name="twitter:image:alt" content={title} />
            <meta name="twitter:site" content={twitterCreator} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:player" content={embedUrl} />
            <meta name="twitter:player:width" content="500" />
            <meta
              name="twitter:player:height"
              content={`${Math.floor(percentage * 500.0)}`}
            />
          </>
        )}

        {!isVideoReady && (
          <>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitterCreator} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:domain" content={baseUrl} />
            <meta name="twitter:image" content={thumbnailToUse} />
            <meta name="twitter:image:alt" content={title} />
            <meta name="twitter:site" content={twitterCreator} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:url" content={url} />
          </>
        )}
        
      </Head>
    </>
  );
};

export default OpenGraph;
