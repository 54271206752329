import { PlayerEvents } from '@sendspark/types';
import { Campaign, DynamicVariables } from '@interfaces';
import { ANALYTICS_TITLE } from '@constants/constants';

export const usePlayerTrackingEvents = (
  segmentTracker: (
    name: string,
    data?: Record<string, unknown>
  ) => Promise<void>,
  campaign: Campaign,
  visitorId: string,
  setShowViralLoop?: React.Dispatch<React.SetStateAction<boolean>>,
  dynamicVariables?: DynamicVariables
): PlayerEvents => {
  const playerTrackers: PlayerEvents = {
    campaignView: async () => { },
    campaignVideoPlay: async () => {
      await segmentTracker(ANALYTICS_TITLE.CampaignVideoPlay, {
        creatorEmail: campaign.creator.email,
        visitor: visitorId,
        campaignId: campaign.id,
        videoId: campaign.meta.video?.id,
        videoUrl: `${campaign.shareUrl}${campaign.shareId}`,
        videoName: campaign.meta.name,
        workspace: {
          id: campaign.creator?.workspaceId,
          name: campaign.creator.workspaceName,
        },
        dynamicVariables: dynamicVariables,
        creatorId: campaign.creator._id,
      });
    },
    campaignResponseClick: async (messageIndex: number) => {
      await segmentTracker(ANALYTICS_TITLE.CampaignResponseClick, {
        creatorEmail: campaign.creator.email,
        visitor: visitorId,
        campaignId: campaign.id,
        videoId: campaign.meta.video?.id,
        workspace: {
          id: campaign.creator?.workspaceId,
          name: campaign.creator.workspaceName,
        },
        buttonText: campaign.messages[messageIndex],
        creatorId: campaign.creator._id,
      });
    },
    campaignCtaClick: async () => {
      await segmentTracker(ANALYTICS_TITLE.CampaignCtaClick, {
        creatorEmail: campaign.creator.email,
        visitor: visitorId,
        campaignId: campaign.id,
        videoUrl: `${campaign.shareUrl}${campaign.shareId}`,
        videoName: campaign.meta.name,
        videoId: campaign.meta.video?.id,
        workspace: {
          id: campaign.creator?.workspaceId,
          name: campaign.creator.workspaceName,
        },
        buttonText: campaign.messages[0]?.text,
        buttonUrl: campaign.messages[0]?.link,
        creatorId: campaign.creator._id,
      });
    },
    campaignVideoLike: async () => {
      await segmentTracker(ANALYTICS_TITLE.CampaignVideoLike, {
        creatorEmail: campaign.creator.email,
        visitor: visitorId,
        campaignId: campaign.id,
        videoId: campaign.meta.video?.id,
        skinTone: localStorage.getItem('thumbUpId'),
        workspace: {
          id: campaign.creator?.workspaceId,
          name: campaign.creator.workspaceName,
        },
        dynamicVariables: dynamicVariables,
        creatorId: campaign.creator._id,
      });
    },
    onEndVideo: async () => {
      setShowViralLoop?.(true);

      const hasDynamicVariables = Object.keys(dynamicVariables || {}).length > 0
      if (hasDynamicVariables) {
        await segmentTracker(ANALYTICS_TITLE.CampaignVideoEnded, {
          creatorEmail: campaign.creator.email,
          visitor: visitorId,
          campaignId: campaign.id,
          videoId: campaign.meta.video?.id,
          workspace: {
            id: campaign.creator?.workspaceId,
            name: campaign.creator.workspaceName,
          },
          dynamicVariables,
          creatorId: campaign.creator._id,
        })
      }
      
    },
    onReplayVideo: function (): void { }
  };

  return playerTrackers;
};
